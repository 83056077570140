import React from 'react';
import ServicesGroups from '../shared/ServicesGroups';
import Footer from '../shared/Footer';
import Cookie from '../shared/Cookie';

const ServicesMain = () => {
  window.scroll(0, 0);
  return (
    <>
      <ServicesGroups />
      <Footer />
      <Cookie />
    </>
  );
};

export default ServicesMain;
