import React from 'react';
import AboutIntro from '../shared/AboutIntro';
import Layout from '../shared/Layout';
import AboutUs from '../shared/AboutUs';
import Industry from '../shared/Industry';
import Footer from '../shared/Footer';
import Career from '../shared/Career';
import Cookie from '../shared/Cookie';

const About = () => {
  window.scroll(0, 0);
  return (
    <>
      <AboutIntro />
      <Layout>
        <AboutUs />
      </Layout>
      <Industry />
      <Layout>
        <Career />
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default About;
