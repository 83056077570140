import React from 'react';
import './options.css';

import Form from '../Form';

const Options = () => {
  const [formActive, setFormActive] = React.useState(false);
  return (
    <>
      <div className="options">
        <h2 className="global-title options__main-title services-page__title">
          <span>Варианты применения </span>
          <span>технологии</span>
        </h2>
        <div className="options__block">
          <div className="options__item red-item">
            <h2 className="options__title white-text">Реконструкция и ремонт трубопроводов</h2>
            <ul className="options__list">
              <li className="options__list-item white-text">
                Подключение вновь построенных трубопроводов к существующим
              </li>
              <li className="options__list-item white-text">Подключение газопроводов-отводов</li>
              <li className="options__list-item white-text">
                Подключение камер запуска-приема устройств внутритрубной диагностики к трубопроводам
              </li>
              <li className="options__list-item white-text">
                Внесение конструктивных изменений в технологическую схему трубопроводов
              </li>
              <li className="options__list-item white-text">
                Переустройство трубопроводов при изменении проектов переходов через авто-, железные
                дороги, водные преграды
              </li>
            </ul>
          </div>
          <div className="options__item">
            <h2 className="options__title">Выборочный капитальный ремонт</h2>
            <ul className="options__list">
              <li className="options__list-item">
                Врезка в трубопровод для организации временного байпаса, лупинга. Перекрытие
                трубопровода под давлением при ремонте трубопроводов с заменой или демонтажем
                дефектных участков труб, запорнорегулирующих арматур, соединительных деталей
                трубопровода без остановки транспорта продукта
              </li>
            </ul>
          </div>
        </div>
        <div className="services__button-block">
          <button
            className="button contacts__button services-contact-button"
            onClick={() => setFormActive(true)}>
            Связаться с нами
            <svg
              className="icon"
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>

      <Form active={formActive} setActive={setFormActive} />
    </>
  );
};

export default Options;
