import React from 'react';

import Layout from '../shared/Layout';
import Intro from '../shared/Intro';
import Header from '../shared/Header';
import Tagline from '../shared/Tagline';
import About from '../shared/About';
import NewsBlock from '../shared/NewsBlock';
import Mission from '../shared/Mission';
import Services from '../shared/Services';
import Contacts from '../shared/Contacts';
import Footer from '../shared/Footer';
import ServicesBottom from '../shared/ServicesBottom';
import Cookie from '../shared/Cookie';

const Home = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
      </Layout>
      <Intro />

      <div className="content">
        <Layout>
          <Tagline />
        </Layout>
        <About />
        <Layout>
          <Mission />
        </Layout>
        <Services />
        <Layout>
          <ServicesBottom />
        </Layout>
        <NewsBlock />
        <Layout>
          <Contacts />
        </Layout>
        <Footer />
      </div>
      <Cookie />
    </>
  );
};

export default Home;
