import React from 'react';
import './video.css';

import video from '../../img/intro.mp4';

const Video = () => {
  return (
    <div className="video">
      <h2 className="news-block__title global-title">фото и видео</h2>
      <video className="video-content" muted="muted" autoplay="autoplay" loop src={video}></video>
    </div>
  );
};

export default Video;
