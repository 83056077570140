import React from 'react';
import './contactsblock.css';

import map from '../../img/map.png';

const ContactsBlock = () => {
  return (
    <div className="contacts contacts-block">
      <div className="contacts__map">
        <svg
          width="549"
          height="615"
          viewBox="0 0 549 615"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3_65)">
            <path
              d="M241.743 388.359L9.31773 515.324L40.9301 296.018C43.2551 279.9 57.0899 267.932 73.3968 267.932H175.762L241.746 388.363L241.743 388.359Z"
              fill="#F0F0F0"
            />
            <path
              d="M257.752 417.578L365.919 615H32.8064C12.8298 615 -2.50714 597.326 0.339654 577.583L3.37553 556.531L257.748 417.578H257.752Z"
              fill="#F0F0F0"
            />
            <path
              d="M332.147 483.969L213.779 267.929H475.596C491.907 267.929 505.738 279.897 508.066 296.015L520.34 381.163L332.147 483.969Z"
              fill="#F0F0F0"
            />
            <path
              d="M516.19 615H403.94L348.156 513.184L525.413 416.357L548.653 577.586C551.5 597.326 536.163 615.003 516.187 615.003L516.19 615Z"
              fill="#F0F0F0"
            />
            <path
              d="M374.845 362.937C407.085 362.937 433.22 353.957 433.22 342.879C433.22 331.801 407.085 322.821 374.845 322.821C342.605 322.821 316.47 331.801 316.47 342.879C316.47 353.957 342.605 362.937 374.845 362.937Z"
              fill="#BCC7C9"
            />
            <path
              d="M486.676 134.464C486.676 227.372 407.42 308.094 380.751 332.626C378.304 334.877 372.541 340.446 372.541 340.446C372.541 340.446 365.962 334.917 363.47 332.626C336.796 308.09 257.544 227.368 257.544 134.464C257.541 68.2867 308.835 14.6428 372.108 14.6428C435.382 14.6428 486.676 68.2867 486.676 134.464Z"
              fill="#FF0028"
            />
            <path
              d="M371.652 214.152C416.449 214.152 452.765 177.89 452.765 133.159C452.765 88.4272 416.449 52.1653 371.652 52.1653C326.855 52.1653 290.539 88.4272 290.539 133.159C290.539 177.89 326.855 214.152 371.652 214.152Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_3_65">
              <rect width="549" height="615" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="contacts__content">
        <h2 className="contacts__title contact-block-title">Контакты</h2>
        <p className="contacts__text">
          ООО Инженерная Компания «Современные Технологические Решения»
        </p>
        <p className="contacts__text">
          108802, г. Москва, п. Сосенское, д. Сосенки, ул. Ясеневая, д.1, корп. 1
        </p>
        <p className="contacts__text">Тел: +7 (495) 128-89-97</p>
        <p className="contacts__text">E-mail: info@ikstr.ru</p>
        <p className="contacts__text">Политика конфиденциальности</p>
        <button className="button contacts__button contact-block-btn">
          Связаться с нами
          <svg
            className="icon"
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
              fill="white"
            />
          </svg>
        </button>
        <div class="img-wrapper">
          <img className="map" src={map} alt="Карта" />
        </div>
      </div>
    </div>
  );
};

export default ContactsBlock;
