import React from 'react';
import { Link } from 'react-router-dom';
import './logo.css';

const Logo = () => {
  return (
    <Link className="logo" to="/">
      <svg
        width="113"
        height="112"
        viewBox="0 0 113 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M111.167 46.1693H79.2257L60.8045 59.9169H67.3537V101.417H83.6985L83.7297 96.4073L83.7588 91.6842L83.8627 75.227L83.9583 59.9169H111.89C111.982 58.6223 112.028 57.3174 112.028 56C112.028 52.6462 111.732 49.361 111.167 46.1693ZM56.0138 0C25.0784 0 0 25.0722 0 56C0 86.9278 25.0784 112 56.0138 112C58.1608 112 60.2808 111.879 62.3655 111.643C64.0989 111.449 65.8094 111.173 67.4909 110.824C71.9325 109.899 75.2684 108.089 79.2548 106.182L83.6881 103.597L67.4888 103.638C65.9404 104.01 64.3649 104.31 62.7624 104.53C62.6294 104.549 62.4964 104.565 62.3634 104.584C60.2829 104.852 58.1649 104.991 56.0117 104.991C28.9484 104.991 7.00848 83.0587 7.00848 56C7.00848 28.9413 28.9505 7.01091 56.0138 7.01091C72.7576 7.01091 87.078 14.7491 95.9155 27.5595L101.694 23.5823C91.5404 9.31117 74.8651 0 56.0138 0ZM56.0138 10.8052C31.0476 10.8052 10.8078 31.04 10.8078 56C10.8078 80.96 31.0476 101.195 56.0138 101.195C58.1691 101.195 60.2891 101.043 62.3655 100.752V89.2384C60.3078 89.6291 58.1857 89.8327 56.0138 89.8327C37.3245 89.8327 22.1748 74.6868 22.1748 56C22.1748 41.359 31.4758 28.8914 44.493 24.1808C44.6115 24.1371 44.732 24.0956 44.8526 24.0519C48.3464 22.8322 52.1022 22.1694 56.0138 22.1694C68.0832 22.1694 74.9711 23.3953 80.9633 32.9018L90.4139 26.5496C80.7035 16.2473 69.5568 10.8052 56.0138 10.8052ZM87.8408 63.04V101.417H88.7886C92.8665 98.4727 96.5286 94.9922 99.6775 91.0795V82.747H105.237C108.503 76.7543 110.704 70.1029 111.589 63.04H87.8408ZM96.909 75.2208V68.9662H106.254V75.2208H96.909Z"
          fill="#E30B00"
        />
        <path
          d="M90.4139 26.5496L80.9425 33.1283C75.0273 26.666 66.4974 22.1693 56.0138 22.1693C52.1021 22.1693 48.3464 22.8322 44.8526 24.0519C44.732 24.0956 44.6115 24.1371 44.493 24.1808C31.4758 28.8914 22.1748 41.3589 22.1748 56C22.1748 74.6867 37.3245 89.8327 56.0138 89.8327C58.1857 89.8327 60.3078 89.6291 62.3655 89.2384V100.752C60.2891 101.043 58.1691 101.195 56.0138 101.195C31.0476 101.195 10.8078 80.9621 10.8078 56C10.8078 31.0379 31.0476 10.8052 56.0138 10.8052C70.012 10.8052 82.1854 16.6566 90.4139 26.5496Z"
          fill="white"
        />
        <path
          d="M92.8976 29.8349L83.4491 36.1871C83.4117 36.1372 83.3763 36.0873 83.3368 36.0375L92.6919 29.5398C92.7605 29.6375 92.829 29.7351 92.8976 29.8349Z"
          fill="black"
        />
        <path
          d="M112.028 56.0001C112.028 57.3175 111.982 58.6224 111.89 59.917H83.9583L83.8627 75.2271L83.7588 91.6842L83.7297 96.4074L83.6985 101.417H67.3537V59.917H49.1653L69.0414 46.1694H111.167C111.732 49.3611 112.028 52.6463 112.028 56.0001Z"
          fill="#E30B00"
        />
        <path
          d="M108.843 36.8064L86.9325 43.676H71.5687L93.5274 28.7149L101.629 23.3518C104.572 27.4786 107.008 31.9939 108.843 36.8064Z"
          fill="#E30B00"
        />
      </svg>
    </Link>
  );
};

export default Logo;
