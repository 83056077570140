import React from 'react';
import Layout from '../shared/Layout';
import HeaderMain from '../shared/HeaderMain';
import Footer from '../shared/Footer';

import newOneIntro from '../img/news-one-intro.png';
import Cookie from '../shared/Cookie';

const NewOne = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <h2 className="news-block__title global-title page-title">Новости</h2>
        <div className="new-one">
          <h2 className="new-one-title">Предквалификация</h2>
          <p className="new-one-main-text">
            В марте 2024г., компания ООО ИК «СТР» успешно завершила процедуру прохождения
            предквалификации по требованиям ПАО «Газпром» и вошла в Реестр потенциальных участников
            закупок Группы ПАО «Газпром».
          </p>
          <div class="img-wrapper">
            <img className="new-one-main-img" src={newOneIntro} alt="Изображение новости" />
          </div>
          {/* <p className="new-one-text">
            Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.
            Вдали от всех живут они в буквенных домах на берегу Семантика большого языкового океана.
            Маленький ручеек Даль журчит по всей стране и обеспечивает ее всеми необходимыми
            правилами. Эта парадигматическая страна, в которой жаренные члены предложения залетают
            прямо в рот. Даже всемогущая пунктуация не имеет власти над рыбными текстами, ведущими
            безорфографичный образ жизни. Однажды одна маленькая строчка рыбного текста по имени
            Lorem ipsum решила выйти в большой мир грамматики. Великий Оксмокс предупреждал ее о
            злых запятых, диких знаках вопроса и коварных точках с запятой, но текст не дал сбить
            себя с толку. Он собрал семь своих заглавных букв, подпоясал инициал за пояс и пустился
            в дорогу. Взобравшись на первую вершину курсивных гор, бросил он последний взгляд назад,
            на силуэт своего родного города Буквоград, на заголовок деревни Алфавит и на
            подзаголовок своего переулка Строчка. Грустный риторический вопрос скатился по его щеке
            и он продолжил свой путь. По дороге встретил текст рукопись. Она предупредила его: «В
            моей стране все переписывается по несколько раз. Единственное, что от меня осталось, это
            приставка «и». Возвращайся ты лучше в свою безопасную страну». Не послушавшись рукописи,
            наш текст продолжил свой путь. Вскоре ему повстречался коварный составитель.
          </p> */}
        </div>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default NewOne;
