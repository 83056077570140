import React from 'react';
import './servicesbottom.css';

import servicesBottom from '../../img/servicesbottom.png';
import { Link } from 'react-router-dom';

const ServicesBottom = () => {
  return (
    <div className="services-bottom">
      <h2 className="services-bottom-tagline">
        <span className="services-bottom-fisrt-span">Поставка оборудования</span>
        <span className="services-bottom-second-span">и расходных материалов</span>
      </h2>
      <div className="services-bottom__content">
        <img className="services-bottom__img" src={servicesBottom} alt="" />
        <div className="services-bottom__block">
          <p className="services-bottom__text">
            Компания ООО ИК «СТР» напрямую осуществляет поставку оборудования и расходных материалов
            производства компании T.D. Williamson для предприятий нефтегазовой, химической и
            энергетической отраслей промышленности.
          </p>
          <div className="services__button-block">
            {/* <button className="button contacts__button services-contact-button">
              Получить каталог
              <svg
                className="icon"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="white"
                />
              </svg>
            </button> */}
            <Link
              to="/supply-of-equipment-and-consumables"
              className="button services__button services__button-white">
              Подробнее
              <svg
                className="icon"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="black"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesBottom;
