import React from 'react';
import './intro.css';

const Intro = () => {
  return (
    <div className="intro">
      <div className="intro__content">
        <h1 className="intro__title">
          <p>Врезка и перекрытие</p>
          <p className="intro__title-p">трубопроводов</p>
          <p>под давлением</p>
        </h1>
        <p className="intro__text">
          Компания работает на рынке добычи и транспортировки нефти и газа, а также оказывает услуги
          по повышению безопасности и эффективности эксплуатации нефтегазопромыслового оборудования
        </p>
      </div>
    </div>
  );
};

export default Intro;
