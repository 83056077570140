import React from 'react';
import Layout from '../shared/Layout';
import HeaderMain from '../shared/HeaderMain';
import Footer from '../shared/Footer';

import newOneIntro from '../img/news-two-intro.png';
import Cookie from '../shared/Cookie';

const NewOne = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <h2 className="news-block__title global-title page-title">Новости</h2>
        <div className="new-one">
          <h2 className="new-one-title">Aттестация</h2>
          <div class="img-wrapper">
            <img className="new-one-main-img" src={newOneIntro} alt="Изображение новости" />
          </div>
          <p className="new-one-text">
            В мае 2023 года в рамках реализации долгосрочной программы развития компании ООО ИК
            «Современные Технологические Решения» выполнена аттестация сварщиков и технологии сварки
          </p>
        </div>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default NewOne;
