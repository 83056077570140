import React from 'react';
import Layout from '../shared/Layout';
import HeaderMain from '../shared/HeaderMain';
import Footer from '../shared/Footer';

import servicesNew from '../img/servicesnew.png';
import servicesNewMobile from '../img/services-block-mobile.png';
import Cookie from '../shared/Cookie';

const ServicesNew = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <div class="img-wrapper">
          <img className="servicesnew" src={servicesNew} alt="" />
          <img className="servicesnew-mobile" src={servicesNewMobile} alt="" />
        </div>
        <p className="servicesnew-text">
           Более подробная информация о продукции на официальном сайте компании
          <a
            className="servicesnew-text-link"
            href=" https://www.tdwilliamson.com/resources/product-bulletins">
            T.D.Williamson
          </a>
        </p>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default ServicesNew;
