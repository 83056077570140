import React from 'react';
import './aboutintro.css';
import { Link } from 'react-router-dom';

import Logo from '../Logo';
import Menu from '../Menu';
import Layout from '../Layout';
import Form from '../Form';

const AboutIntro = () => {
  const [modalActive, setModalActive] = React.useState(false);
  const [formActive, setFormActive] = React.useState(false);

  return (
    <>
      <div className="aboutintro">
        <div className="aboutintro__layout">
          <Layout>
            <header className="header aboutintro__intro">
              <Logo />
              <Menu />
              <button className="header__burger-button" onClick={() => setModalActive(true)}>
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 2H20" stroke="white" stroke-width="2.29787" stroke-linecap="round" />
                  <path d="M2 18H20" stroke="white" stroke-width="2.29787" stroke-linecap="round" />
                  <path d="M2 10H20" stroke="white" stroke-width="2.29787" stroke-linecap="round" />
                </svg>
              </button>
              <button className="button aboutintro_button" onClick={() => setFormActive(true)}>
                Связаться с нами
                <svg
                  className="icon"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="white"
                  />
                </svg>
              </button>
            </header>
          </Layout>
        </div>

        <div className={modalActive ? 'header__burger-button-active' : 'burger-menu'}>
          <div className="burger-menu-container" onClick={(el) => el.stopPropagation()}>
            <button className="burger-menu-close" onClick={() => setModalActive(false)}>
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_360_47)">
                  <path
                    d="M24.5416 8.27975L22.7203 6.4585L15.4999 13.6789L8.2795 6.4585L6.45825 8.27975L13.6787 15.5002L6.45825 22.7206L8.2795 24.5418L15.4999 17.3214L22.7203 24.5418L24.5416 22.7206L17.3212 15.5002L24.5416 8.27975Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_360_47">
                    <rect width="31" height="31" rx="15.5" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <ul className="burger-menu-list">
              <li className="burger-menu-item">
                <Link className="burger-menu-link" to="/about">
                  О нас
                </Link>
              </li>
              <li className="burger-menu-item">
                <Link className="burger-menu-link" to="/services">
                  Услуги
                </Link>
              </li>
              <li className="burger-menu-item">
                <Link className="burger-menu-link" to="/news">
                  Новости
                </Link>
              </li>
              <li className="burger-menu-item">
                <Link className="burger-menu-link" to="/media">
                  Медиа
                </Link>
              </li>
              <li className="burger-menu-item">
                <Link className="burger-menu-link" to="/contacts">
                  Контакты
                </Link>
              </li>
            </ul>
            <div className="burger-menu-contacts">
              <button className="button burger-button-contact" onClick={() => setFormActive(true)}>
                Связаться с нами
                <svg
                  className="icon"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="aboutintro__content">
          <h1 className="aboutintro__title">о нашей компании</h1>
          <p className="aboutintro__text">
            ООО ИК «СТР» создана в рамках мер по автономизации нефтегазовой отрасли от зарубежных
            технологий и обеспечения развития российского технологического суверенитета.
          </p>
        </div>
      </div>
      <Form active={formActive} setActive={setFormActive} />
    </>
  );
};

export default AboutIntro;
