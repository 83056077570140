import React from 'react';
import './application.css';

const Application = () => {
  return (
    <div className="application">
      <h2 className="global-title services-page__title">ПРИМЕНЕНИЕ</h2>
      <div className="application__block">
        <div className="application-item application-item-first">
          <span className="application__number application__number-fisrt">01</span>
          <p className="application__text">Подключение насосных и компрессорных станций</p>
        </div>
        <div className="application-item application-item-second">
          <span className="application__number application__number-second">02</span>
          <p className="application__text">
            Замена переходов под железными и автомобильными дорогами
          </p>
        </div>
        <div className="application-item application-item-third">
          <span className="application__number application__number-third">03</span>
          <p className="application__text">
            Замена дефектных участков трубопроводов и негерметичной запорной арматуры
          </p>
        </div>
        <div className="application-item application-item-fourth">
          <span className="application__number application__number-fourth">04</span>
          <p className="application__text">
            Подключение месторождений нефти и газа к действующим магистральным сетям
          </p>
        </div>
        <div className="application-item application-item-fifth">
          <span className="application__number application__number-fifth">05</span>
          <p className="application__text">
            Подключение газораспределительных станций к действующим газопроводам при газификации
            регионов
          </p>
        </div>
      </div>
    </div>
  );
};

export default Application;
