import React from 'react';
import './servicesintro.css';
import HeaderMain from '../HeaderMain';

import servicesIntro from '../../img/servicesintro.png';
import servicesImgFirst from '../../img/services-img-1.png';
import servicesImgSecond from '../../img/services-img-2.png';
import servicesImgThird from '../../img/services-img-3.png';
import servicesImgFourth from '../../img/services-img-4.png';
import servicesImgFifth from '../../img/services-img-5.png';
import servicesImgSixth from '../../img/services-img-6.png';
import servicesImgSeventh from '../../img/services-img-7.png';

const ServicesIntro = () => {
  return (
    <div className="services-intro">
      <HeaderMain />
      <div class="img-wrapper">
        <img
          className="services-intro__img"
          src={servicesIntro}
          alt="Изображение на странице Услуги"
        />
      </div>
      <h2 className="global-title services-intro__title first-services-title">
        Общая схема врезки через тройник с боковым отводом (типовая)
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-image-fisrt"
          src={servicesImgFirst}
          alt=""
        />
      </div>
      <h2 className="global-title services-intro__title second-services-title">
        Двустороннее перекрытие с байпасной линией через разрезные тройники
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-image-second"
          src={servicesImgSecond}
          alt=""
        />
      </div>
      <h2 className="global-title services-intro__title second-services-title">
        Двустороннее перекрытие без байпасной линии
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-image-second"
          src={servicesImgFifth}
          alt=""
        />
      </div>
      <h2 className="global-title services-intro__title second-services-title">
        Одностороннее перекрытие без байпасной линии
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-image-second"
          src={servicesImgSixth}
          alt=""
        />
      </div>
      <h2 className="global-title services-intro__title third-services-title">
        ДВУСТОРОННЕЕ ПЕРЕКРЫТИЕ С БАЙПАСНОЙ ЛИНИЕЙ ЧЕРЕЗ адаптеры для перекрытия с боковым отводом
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-image-third"
          src={servicesImgThird}
          alt=""
        />
      </div>
      <h2 className="global-title services-intro__title third-services-title">
        Врезка через кран заказчика
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-image-third"
          src={servicesImgSeventh}
          alt=""
        />
      </div>
      <h2 className="global-title services-intro__title fourth-services-title">
        Двустороннее перекрытие с байпасной линией через спул-адаптеры
      </h2>
      <div class="img-wrapper">
        <img
          className="services-intro-image services-intro-fourth"
          src={servicesImgFourth}
          alt=""
        />
      </div>
    </div>
  );
};

export default ServicesIntro;
