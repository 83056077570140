import React from 'react';
import { Link } from 'react-router-dom';
import './menu.css';

const Menu = () => {
  const menuItems = ['О нас', ' Услуги', 'Новости', 'Медия', 'Контакты'];

  return (
    <ul className="menu">
      <li className="menu__item">
        <Link className="menu__link" to="/about">
          О нас
        </Link>
      </li>
      <li className="menu__item">
        <Link className="menu__link" to="/services">
          Услуги
        </Link>
      </li>
      <li className="menu__item">
        <Link className="menu__link" to="/news">
          Новости
        </Link>
      </li>
      <li className="menu__item">
        <Link className="menu__link" to="/media">
          Медиа
        </Link>
      </li>
      <li className="menu__item">
        <Link className="menu__link" to="/contacts">
          Контакты
        </Link>
      </li>
      {/* {menuItems.map((menuName, index) => (
        <li className="menu__item">
          <Link className="menu__link">{menuName}</Link>
        </li>
      ))} */}
    </ul>
  );
};

export default Menu;
